<template>
  <div>
    <banner/>

<!--    <statistic/>-->

<!--    <companion/>-->
    <supply-chain/>
    <why-harvest-with-sgara/>
    <harvest-step1/>
    <harvest-step2/>
    <sgara-map2/>
    <contact-us/>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    HarvestStep2: () => import("@/components/Home/HarvestStep2"),
    HarvestStep1: () => import("@/components/Home/HarvestStep1"),
    WhyHarvestWithSgara: () => import("@/components/Home/WhyHarvestWithSgara"),
    SgaraMap2: () => import("@/components/Home/SgaraMap2"),
    SupplyChain: () => import("@/components/Home/SupplyChain"),
    // StartNow: () => import("@/components/Home/StartNow"),
    ContactUs: () => import("@/components/Home/ContactUs"),
    // Partners: () => import("@/components/Home/Partners"),
    Banner: () => import("@/components/Home/Banner")
  },
  created() {
    document.querySelector('body').classList.add('home')
  },
  mounted() {
    this.$router.push('/').catch(() => {})
  },
  beforeDestroy() {
    document.querySelector('body').classList.remove('home')
  },
}
</script>
